import { EnvironmentProviders, Provider } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { OIDC } from 'src/environments/environment';

const isIE: boolean =
  window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function instanceFactory(): IPublicClientApplication {
  const instanceFactory = new PublicClientApplication({
    auth: {
      clientId: OIDC.clientId,
      authority: OIDC.authorityId,
      redirectUri: OIDC.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
  instanceFactory.initialize();

  return instanceFactory;
}

export function interceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap: Map<string, Array<string>> = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', OIDC.scopes);
  protectedResourceMap.set('/', OIDC.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function guardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: OIDC.scopes,
    },
  };
}

export function getAuthProviders(): (Provider | EnvironmentProviders)[] {
  return [
    {
      provide: MSAL_INSTANCE,
      useFactory: instanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: guardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: interceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ];
}

export function authRedirectComponent() {
  return MsalRedirectComponent;
}
