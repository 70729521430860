import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IHttpOptions } from 'src/app/shared/models';
import { CommonUtils } from 'src/app/shared/utils/common.utils';
import { ApiEndpoints } from './api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class DataIngestionMicroService {
  constructor(
    private http: HttpClient,

    public backend: HttpBackend
  ) {}

  getNosieTestRecordsList<T>(options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(ApiEndpoints.GET_NOSIE_TR_LIST, options);
  }

  getTestRecords<T>(options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(ApiEndpoints.GET_TEST_RECORDS, options);
  }

  deleteUnusedTils(tils) {
    return this.http.patch(ApiEndpoints.SOFT_DELETE_UNUSED_TILS, {
      tilbts: tils
    });
  }
}
