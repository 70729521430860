<h2 mat-dialog-title>{{ data?.title }}</h2>
<div mat-dialog-content>
  <ng-container
    *ngTemplateOutlet="data?.ngTemplate; context: { $implicit: data?.context }"
  ></ng-container>
</div>
<div mat-dialog-actions align="end">
  <de-button
    class="mr-1"
    *ngFor="let action of data.actions"
    [type]="action.fn ? 'primary' : 'outline'"
    [disabled]="!action.active"
    (onClick)="onButtonClick(action)"
    >{{ action.displayName }}</de-button
  >
</div>

<ng-template #textTemplate let-data>
  <h2>
    {{ data.text }} {{ data.time ? ":" + (data.time | async) + "seconds" : "" }}
  </h2>
</ng-template>
