<ng-template #statusTemplate let-row [appCellTemplate]="'statusTemplate'">
  <div
    appCustomToolTip
    [contentTemplate]="statusTooltip"
    [data]="row"
    class="progress"
    style="max-width: 70%; min-width: 40%"
  >
    <div></div>
    <div
      *ngIf="row.percent?.Open"
      class="progress-bar bg-open"
      [style.width]="row.percent.Open"
    ></div>
    <div
      *ngIf="row.percent?.In_Progress"
      class="progress-bar bg-pending"
      [style.width]="row.percent.In_Progress"
    ></div>
    <div
      *ngIf="row.percent?.System_Failure"
      class="progress-bar bg-error"
      [style.width]="row.percent.System_Failure"
    ></div>
    <div
      *ngIf="row.percent?.Below_Threshold"
      class="progress-bar bg-success-below-threshold"
      [style.width]="row.percent?.Below_Threshold"
    ></div>
    <div
      *ngIf="row.percent?.Complete"
      class="progress-bar bg-success"
      [style.width]="row.percent?.Complete"
    ></div>
    <div
      *ngIf="row.percent?.Approved"
      class="progress-bar bg-approved"
      [style.width]="row.percent?.Approved"
    ></div>
  </div>
</ng-template>

<ng-template #statusTooltip let-row [appCellTemplate]="'statusTooltip'">
  <div>
    <div *ngIf="row.percent.Open">
      <span class="d-flex align-items-center">
        <mat-icon matTooltip="Open" class="pending">panorama_fish_eye</mat-icon>
        Open - {{ row.percent.Open }}
      </span>
    </div>
    <div *ngIf="row.percent.In_Progress">
      <span class="d-flex align-items-center">
        <mat-icon class="pending">rotate_right</mat-icon>
        In Progress - {{ row.percent.In_Progress }}
      </span>
    </div>
    <div *ngIf="row.percent.System_Failure">
      <span class="d-flex align-items-center">
        <mat-icon class="error">highlight_off</mat-icon>
        System Failure - {{ row.percent.System_Failure }}
      </span>
    </div>
    <div *ngIf="row.percent.Below_Threshold">
      <span class="d-flex align-items-center"
        ><mat-icon class="success-below-threshold"
          >check_circle_outline</mat-icon
        >
        Below Threshold - {{ row.percent.Below_Threshold }}
      </span>
    </div>
    <div *ngIf="row.percent.Complete">
      <span class="d-flex align-items-center">
        <mat-icon class="success">check_circle_outline</mat-icon>
        Complete - {{ row.percent.Complete }}
      </span>
    </div>
    <div *ngIf="row.percent.Approved">
      <span class="d-flex align-items-center">
        <mat-icon class="success">fact_check </mat-icon>
        Approved - {{ row.percent.Approved }}
      </span>
    </div>
  </div>
</ng-template>
