import { inject } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './core/login/login.component';
import { AuthService } from './core/services/auth/auth.service';

const FeatureModulesFunctionalGuard = () => {
  const authenticated: boolean = inject(AuthService).isAuthenticated();
  if (!authenticated) inject(Router).navigate(['login']);
  return authenticated;
};

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [FeatureModulesFunctionalGuard, MsalGuard],
    canActivateChild: [FeatureModulesFunctionalGuard, MsalGuard],
    loadChildren: () =>
      import('./feature/feature.routes').then((m) => m.featureRoutes),
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Login',
  },
];
