export const OIDC = {
  scopes: ['openid', 'profile', 'email'],
  clientId: '3d03f8aa-978c-4254-9f1f-2ceb4d09855f',
  authorityId: 'https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477/',
  redirectUri: window.location.origin + '/',
};

export const SERVICE_ENDPOINTS = {
  dataIngestion: 'dige-data-ingestion',
  fileInventory: 'dige-file-inventory',
  processController: 'dige-process-controller',
  identification: 'dige-identification',
  InfoExtractionAndValidation: 'dige-information-extraction-and-validation',
  persistenceAndReporting: 'dige-persistence-and-reporting',
  modelTraining: 'dige-model-training',
  userManagement: 'dige-user-management',
  dataExtraction: 'dige-data-extraction',
  issueTrackingSystem: 'gen-dige-issue-tracking-system',
};

export const environment = {
  production: false,
  dev: true,
  local: false,
  baseUrl: 'https://digitaleye-api.mosaic-dev.siemens-energy.cloud/v1/',
  baseWebsocketUrl: 'wss://q0w6uiy3b0.execute-api.eu-central-1.amazonaws.com/dev',
};
