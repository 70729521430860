import { HttpParams } from '@angular/common/http';
import { IHttpOptions } from '../models/core.model';

export class CommonUtils {
  /**
   * to set reset value to true, to avoid caching for that API
   */
  static noCachingOfAPI(params?: HttpParams | {}): IHttpOptions {
    return {
      headers: {
        reset: 'true',
      },
      params,
    };
  }

  static getReplacedEndpoint(url: string, id: any): string {
    return url.replace(':id', id);
  }

  static prepareParams(pageData: any): string {
    let pageDetails: string = '';
    let i: number = 0;
    for (const pageProp in pageData) {
      if (i === 0) {
        pageDetails += `${pageProp}=${pageData[pageProp]}`;
      } else {
        if (pageData[pageProp]) {
          pageDetails += `&${pageProp}=${pageData[pageProp]}`;
        }
      }
      i++;
    }

    return pageDetails;
  }

  static getNonEmptyParams(allParams: {}): {} {
    const params: {} = {};
    for (const key in allParams) {
      if (allParams[key]) {
        params[key] = allParams[key];
      }
    }

    return params;
  }


}
