import { SERVICE_ENDPOINTS } from 'src/environments/environment';

const SERVICE_EP: string = SERVICE_ENDPOINTS.identification;

export const ApiEndpoints = {
  START_IDENTIFICATION: SERVICE_EP + `/start_identification_process`,
  CREATE_IDENTIFIED_ROW: SERVICE_EP + `/identified_row/add`,
  RETRIEVE_FILE_DETAILS: SERVICE_EP + `/retrieve_file_details`,
  UPDATE_IDENTIFIED_ROW: SERVICE_EP + `/identified_row/:id/update`,
  DELETE_IDENTIFIED_ROW: SERVICE_EP + `/identified_row/:id/delete`,
};
