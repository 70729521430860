import { ComponentType } from '@angular/cdk/portal';
import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { DeDialogComponent } from 'src/app/shared/components/molecules/de-dialog/de-dialog.component';
import { IDialogData } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  dialogRef;
  constructor(private dialog: MatDialog) {}

  openDialog(data: IDialogData, config?: MatDialogConfig) {
    let dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = 'de-dialog-container';
    dialogConfig.minWidth = '40vw';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.minHeight = '25vh';
    dialogConfig.maxHeight = '80vh';

    // update the given config in dialogConfig
    let finalConfig = Object.assign(dialogConfig, config);
    finalConfig.data = data;

    this.dialogRef = this.dialog.open(DeDialogComponent, finalConfig);
    this.dialogRef
      .afterClosed()
      .pipe(finalize(() => (this.dialogRef = undefined)))
      .subscribe();
  }
  confirmDialog(
    message: string,
    yesCallback = () => {},
    noCallback = () => {},
    title = 'Confirm',
    yesText = 'Yes',
    noText = 'No'
  ) {
    const dialogData: IDialogData = {
      title,
      context: {
        text: message,
      },
      template: 'text',
      actions: [
        {
          displayName: yesText,
          closeDialog: true,
          active: () => true,
          fn: (context: any) => {
            yesCallback();
          },
        },
        {
          displayName: noText,
          closeDialog: true,
          active: () => true,
          fn: (context: any) => {
            noCallback();
          },
        },
      ],
    };

    this.openDialog(dialogData, { maxWidth: '40vw' });
  }

  forcedExitDialog(message: string, yesCallback = () => {}, title = 'Confirm') {
    const dialogData: IDialogData = {
      title,
      context: {
        text: message,
      },
      template: 'text',
      actions: [
        {
          displayName: 'OK',
          closeDialog: true,
          active: () => true,
          fn: (context) => {
            yesCallback();
          },
        },
      ],
    };

    this.openDialog(dialogData, { maxWidth: '40vw' });
  }

  sessionTimeoutDialog(
    message: any,
    yesCallback = () => {},
    title = 'Confirm'
  ) {
    const dialogData: IDialogData = {
      title,
      context: {
        text: message.text,
        time: message.time,
      },
      template: 'text',
      actions: [
        {
          displayName: 'Extend Session',
          closeDialog: true,
          active: () => true,
          fn: (context) => {
            yesCallback();
          },
        },
      ],
    };

    this.openDialog(dialogData, { maxWidth: '40vw' });
  }
  openCustomTemplate(
    template: TemplateRef<any>,
    config?: MatDialogConfig<any>
  ): MatDialogRef<any, any> {
    return this.dialog.open(template, config);
  }

  openCustomComponent(
    component: ComponentType<any>,
    config?: MatDialogConfig<any>
  ): MatDialogRef<any, any> {
    return this.dialog.open(component, config);
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }
}
