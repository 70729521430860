import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[appCellTemplate]',
    standalone: true,
})
export class CellTemplateDirective {
  @Input('appCellTemplate') name: string;
  constructor(public template: TemplateRef<any>) {}
}
