import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  DURATION = 5000;
  constructor(private _snackBar: MatSnackBar) {}

  snackBarSuccess(message: string) {
    this._snackBar.open(message, null, {
      duration: this.DURATION,
      panelClass: 'alert-success',
    });
  }
  snackBarInfo(message: string) {
    this._snackBar.open(message, null, {
      duration: this.DURATION,
      panelClass: 'alert-info',
    });
  }
  snackBarWarning(message: string) {
    this._snackBar.open(message, null, {
      duration: this.DURATION,
      panelClass: 'alert-warning',
    });
  }
  snackBarDanger(message: string, duration?: number) {
    this._snackBar.open(message, null, {
      duration: duration ?? this.DURATION,
      panelClass: 'alert-danger',
    });
  }
}
