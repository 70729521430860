@switch (type) { @case ('primary') {
<button
  [attr.aria-labelledby]="tooltip"
  tabindex="0"
  mat-raised-button
  [class]="'de-button de-button-primary ' + customClass"
  (click)="onClickButton($event)"
  [disabled]="disabled"
  [matTooltip]="tooltip"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>
} @case ('outline') {
<button
  [attr.aria-labelledby]="tooltip"
  mat-stroked-button
  [class]="'de-button de-button-outline ' + customClass"
  (click)="onClickButton($event)"
  [disabled]="disabled"
  [matTooltip]="tooltip"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>
} @case ('fab') {
<button
  [attr.aria-labelledby]="tooltip"
  color="primary"
  class="de-button de-fab {{ backgroundColorClass }} {{
    size === 'small' ? 'small-size' : ''
  }}"
  mat-fab
  aria-label="Button"
  (click)="onClickButton($event)"
  [disabled]="disabled"
  [matTooltip]="tooltip"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>
} }
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
