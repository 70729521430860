import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IHttpOptions } from 'src/app/shared/models';
import { CommonUtils } from 'src/app/shared/utils/common.utils';
import { ApiEndpoints } from './api-endpoints';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileInventoryMicroService {
  constructor(private http: HttpClient) {}

  getExtractionSchema<T>(options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(ApiEndpoints.GET_ESCHEMA_CATEGORIES, options);
  }

  getFileInventory<T>(options: IHttpOptions): Observable<T> {
    return this.http.get<T>(ApiEndpoints.GET_FILE_DETAILS, options);
  }

  getJobFileInventory<T>(options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(ApiEndpoints.GET_FILE_DETAILS, options);
  }

  getFilesStats<T>(options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(ApiEndpoints.GET_FILE_STATS, options);
  }

  downloadFile(id: number, options?: IHttpOptions): Observable<any> {
    return this.http.get(
      CommonUtils.getReplacedEndpoint(ApiEndpoints.DOWNLOAD_FILE, id)
    );
  }

  deleteFile<T>(id: number, options?: IHttpOptions): Observable<T> {
    return this.http.delete<T>(
      CommonUtils.getReplacedEndpoint(ApiEndpoints.DELETE_FILE, id),
      options
    );
  }

  uploadFiles<T>(
    payloadBody: any,
    options?: IHttpOptions,
    metaData?
  ): Observable<T> {
    return this.http.post<T>(
      `${ApiEndpoints.UPLOAD_FILE}`,
      payloadBody,
      options
    );
  }

  addMetaData<T>(metaData: any, options?: IHttpOptions): Observable<T> {
    return this.http.post<T>(ApiEndpoints.UPLOAD_FILE, metaData, options);
  }

  getPdfs<T>(params: any, options: IHttpOptions): Observable<T> {
    return this.http.get<T>(
      `${ApiEndpoints.GET_COMPARISON_PATHS}/${params.reportId}/${params.page_no}/${params.til}/${params.template_page_no}`,
      options
    );
  }

  getDownloadFile(url: string): Observable<HttpEvent<ArrayBuffer>> {
    return this.http
      .get<ArrayBuffer>(`${url}`, {
        responseType: 'arrayBuffer' as 'json',
        observe: 'events',
        reportProgress: true,
      })
      .pipe
      // tap(())
      ();
  }

  getDownloadFileJob(url: string): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${url}`, {
      responseType: 'arrayBuffer' as 'json',
    });
  }

  updateFileTable(fileID: number, payload): Observable<any> {
    return this.http.patch(
      ApiEndpoints.UPDATE_FILE + fileID,
      payload,
      CommonUtils.noCachingOfAPI()
    );
  }
}
