import { SERVICE_ENDPOINTS } from 'src/environments/environment';

const SERVICE_EP: string = SERVICE_ENDPOINTS.fileInventory;

export const ApiEndpoints = {
  GET_FILE_DETAILS: SERVICE_EP + `/files/inventory`,
  UPLOAD_FILE: SERVICE_EP + `/files/create`,
  DELETE_FILE: SERVICE_EP + `/files/:id/delete`,
  DOWNLOAD_FILE: SERVICE_EP + `/files/:id/download`,
  GET_FILE_STATS: SERVICE_EP + '/files_stats',
  GET_ESCHEMA_CATEGORIES: SERVICE_EP + '/category',
  GET_COMPARISON_PATHS: SERVICE_EP + '/files/get_comparison_paths',
  UPDATE_FILE: SERVICE_EP + '/files/update/',
};
