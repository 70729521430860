import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { HotkeyModule } from 'angular2-hotkeys';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { getAuthProviders } from './app/shared/utils/auth.utils';
import { authInterceptor } from './app/core/services/http-interceptor/http-interceptor.function';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideRouter, withRouterConfig } from '@angular/router';
import { appRoutes } from './app/app.routes';

if (environment.production) enableProdMode();

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(appRoutes, withRouterConfig({})),
    importProvidersFrom(BrowserModule, HotkeyModule.forRoot()),
    provideHttpClient(withInterceptors([authInterceptor])),
    ...getAuthProviders(),
    provideAnimations(),
  ],
}).catch((err: Error): void => console.error(err));
