import { inject } from '@angular/core';
import {
  HttpRequest,
  HttpEvent,
  HttpInterceptorFn,
  HttpHandlerFn,
} from '@angular/common/http';
import { AuthenticationResult } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MsalInterceptorAuthRequest } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
let authService: AuthService;
const injectServices = () => {
  authService = inject<AuthService>(AuthService);
};

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  injectServices();
  authService.checkInteractionType();
  if (req.url.startsWith('http')) return next(req);
  const scopes = authService.getScopesForEndpoint(req.url, req.method);
  if (!scopes || scopes.length === 0) return next(req);

  const account = authService.getAccountInfo();
  const authRequest: MsalInterceptorAuthRequest = authService.getAuthRequest(
    account,
    req
  );
  return authService.acquireToken(authRequest, scopes, account).pipe(
    switchMap((result: AuthenticationResult) => {
      return acquireTokenSuccess(result, req, next);
    })
  );
};

const acquireTokenSuccess = (
  result: AuthenticationResult,
  req: HttpRequest<any>,
  next: HttpHandlerFn
) => {
  authService.authResult = result;
  const headers = setTokenAndUpdateHeader(req, result);
  const requestClone = req.clone({
    url: environment.baseUrl + req.url,
    headers,
  });
  return next(requestClone);
};

const setTokenAndUpdateHeader = (
  req: HttpRequest<any>,
  result: AuthenticationResult
) => {
  const headers = req.headers.set('access_token', `Bearer ${result.idToken}`);
  localStorage.setItem('access_token', result.accessToken);
  localStorage.setItem('auth_token', `Bearer ${result.idToken}`);
  return headers;
};
