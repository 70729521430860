import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IHttpOptions } from 'src/app/shared/models';
import { ApiEndpoints } from './api-endpoints';
import { ApiEndpoints as FileInventoryAPIs } from '../file-inventory/api-endpoints';
import { CommonUtils } from 'src/app/shared/utils/common.utils';

@Injectable({
  providedIn: 'root',
})
export class IdentificationMicroService {
  constructor(private http: HttpClient) {}

  startIdentificationProcess<T>(
    body: any,
    options?: IHttpOptions
  ): Observable<T> {
    return this.http.post<T>(ApiEndpoints.START_IDENTIFICATION, body, options);
  }

  getFileDetails<T>(options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(ApiEndpoints.RETRIEVE_FILE_DETAILS, options);
  }

  getPDFDetails<T>(options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(FileInventoryAPIs.GET_FILE_DETAILS, options);
  }

  createIdentifiedRow<T>(body: any, options?: IHttpOptions): Observable<T> {
    return this.http.post<T>(ApiEndpoints.CREATE_IDENTIFIED_ROW, body, options);
  }

  updateIdentifiedRow<T>(
    id: number,
    body: any,
    options?: IHttpOptions
  ): Observable<T> {
    return this.http.put<T>(
      CommonUtils.getReplacedEndpoint(ApiEndpoints.UPDATE_IDENTIFIED_ROW, id),
      body,
      options
    );
  }

  deleteIdentifiedRow<T>(id: number, options?: IHttpOptions): Observable<T> {
    return this.http.delete<T>(
      CommonUtils.getReplacedEndpoint(ApiEndpoints.DELETE_IDENTIFIED_ROW, id),
      options
    );
  }
}
