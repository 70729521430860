import { Component, ViewChild } from '@angular/core';
import { DialogService } from '../core/services/dialog/dialog.service';
import { AuthService } from '../core/services/auth/auth.service';
import { CookiesUtil } from '../shared/utils/cookie.util';
import { DeButtonComponent } from '../shared/components/atoms/de-button/de-button.component';
import { MatTooltip } from '@angular/material/tooltip';
@Component({
    selector: 'de-disclaimer',
    template: `
    <div style="padding: 10px;">
      <div #contentContainer style="overflow: auto; max-height: 70vh;">
        <h2>Digital Eye Confidentialty Agreement (Read Carefully)</h2>
        In accordance with published Siemens Energy procedures (which I
        acknowledge has having been received and agreed to by me) I acknowledge
        my obligations therein and will comply with all applicable
        confidentiality agreements ("Agreements") signed between Siemens Energy
        and its customers ("Customers"). In addition to all other obligations, I
        will maintain all information gained from the Digital Eye system as
        confidential in accordance with the terms and conditions of all such
        Agreements. In summary, specifically with respect to information which I
        have gained or otherwise had access to dealing with access and/or use of
        the Digital Eye system:
        <div>
          1. I will conspicuously mark all hardcopy information as
          "Confidential" and "Siemens Energy Proprietary". Visual or oral
          information will be documented in written form and marked as
          "Confidential" and "Siemens Energy Proprietary" within 30 days of
          disclosure.
        </div>
        <div>
          2. I will not transmit or disclose any information to and/or between
          Customers or any third parties.
        </div>
        <div>
          3. I will not copy nor otherwise reproduce, or excerpt any information
          except where necessary for permitted use. I will shred hardcopies of
          the information when no longer required for work-related activities.
        </div>
        <div>
          4. I shall maintain the information in accordance with the terms of
          the Agreements for the duration of the Agreements.
        </div>

        <h2>Attention A Region User</h2>

        Your profile indicates that you are required to adhere to United States
        export control restrictions regarding business activities with embargoed
        countries. Therefore you are asked to confirm that you will not use your
        system access to directly or indirectly facilitate an export transaction
        or financial benefit to an embargoed country, which currently includes
        Cuba, Iran, North Korea & Syria. For a complete list of countries,
        please refer to the "Summary of US Sanctioned Countries" list found at
        the following link:
        <a
          target="_blank"
          href="https://siemensenergyagnam.sharepoint.com/sites/Functions-Legal-Compliance-NA/SitePages/en-Export-Control-USA.aspx"
          >https://siemensenergyagnam.sharepoint.com/sites/Functions-Legal-Compliance-NA/SitePages/en-Export-Control-USA.aspx</a
        >
        <div></div>
        <h3 #disclaimerRef style="background-color: bisque;">
          This End-User-Agreement("EUA") is a legal agreement between you and
          Siemens Energy, Inc, for the software that accompanies EUA. By
          clicking on the "I Agree" button, YOU AGREE TO BE BOUND BY THE TERMS
          OF THIS EUA. IF YOU DO NOT AGREE, click on "I decline" button. IF YOU
          DECLINE, DO NOT RUN, COPY OR USE THIS SOFTWARE
        </h3>
      </div>
      <div>
        <de-button
          [matTooltip]="!showAgreeButton ? 'Please read till the end' : ''"
          [disabled]="!showAgreeButton"
          (onClick)="disclaimerAgreed()"
          >I Agree</de-button
        >
        <de-button (onClick)="disclaimerDeclined()">I Decline</de-button>
      </div>
    </div>
  `,
    standalone: true,
    imports: [DeButtonComponent, MatTooltip]
})
export class DisclaimerComponent {
  @ViewChild('contentContainer') contentContainer;
  @ViewChild('disclaimerRef') disclaimerRef;
  showAgreeButton = false;

  constructor(
    private dialogService: DialogService,
    private authService: AuthService
  ) {}
  disclaimerAgreed() {
    CookiesUtil.set('disclaimer', 'popup', 1);
    this.dialogService.closeDialog();
  }

  disclaimerDeclined() {
    this.authService.logout();
  }
}
