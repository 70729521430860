import { Component } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';

@Component({
  selector: 'de-loader',
  template: `
    <p-progressBar
      mode="indeterminate"
      [style]="{ height: '6px', background: 'transparent' }"
    ></p-progressBar>
  `,
  styleUrls: ['./de-loader.component.scss'],
  standalone: true,
  imports: [ProgressBarModule],
})
export class DeLoaderComponent {}
