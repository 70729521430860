import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton, MatFabButton } from '@angular/material/button';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'de-button',
  templateUrl: './de-button.component.html',
  styleUrls: ['./de-button.component.scss'],
  standalone: true,
  imports: [MatButton, MatTooltip, NgTemplateOutlet, MatFabButton],
})
export class DeButtonComponent {
  @Input() customClass: string = '';
  @Input() type: string = 'primary'; // 'primary' | 'outline'
  @Input() size: 'normal' | 'small' = 'normal';
  @Input() disabled: boolean = false;
  @Input() tooltip: string;
  @Input() backgroundColorClass: string;
  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() {}
  onClickButton(event: MouseEvent): void {
    if (!this.disabled) {
      this.onClick.emit(event);
    }
  }
}
