import { ApplicationRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading: Observable<boolean> = this.isLoading.asObservable();
  constructor(private cdRef: ApplicationRef) {}

  /**
   * true to start the loader
   * false to stop loader
   * @param state loader state (true/fale)
   */
  changeLoadingStateTo(state: boolean): void {
    this.isLoading.next(state);
    // this.cdRef.tick();
  }
}
