import { IPrivileges } from 'src/app/shared/models/privileges.model';

export const PRIVILEGES: IPrivileges = {
  isAdmin: {
    dashboardModule: {},
    jobModule: {
      jobList: {
        canCreateJob: true,
        canDeleteOwnJob: true,
        canDeleteOthersJob: true,
        canEditJob: true,
      },
      jobDetail: {
        canStartJob: true,
        canReRunFiles: true,
        canExport: true,
      },
      fileDetail: {
        canStartDataExtraction: true,
        canCreateNewPage: true,
        canDeletePage: true,
        canEditPage: true,
        canExport: true,
        canViewPageVerification: true,
      },
      pageVerification: {
        canExport: true,
        canSave: true,
        canSaveAndApprove: true,
        canCheckLogs: true,
      },
    },
    fileInventoryModule: {
      fileCategory: {
        canUpload: true,
        canEdit: true,
        canDelete: true,
        canDownload: true,
      },
    },
    extractionSchemaModule: {
      listing: {
        canDeleteSchema: true,
        canCreateSchema: true,
        canViewSchema: true,
        canExport: true
      },
      createExtractionSchema: {
        canUploadBlankTemplate: true,
        canSave: true,
      },
    },
    efrwModule: {
      canExport: true,
    },
  },
  isSuperUser: {
    dashboardModule: {},
    jobModule: {
      jobList: {
        canCreateJob: true,
        canDeleteOwnJob: true,
        canDeleteOthersJob: false,
        canEditJob: true,
      },
      jobDetail: {
        canStartJob: true,
        canReRunFiles: true,
        canExport: true,
      },
      fileDetail: {
        canStartDataExtraction: true,
        canCreateNewPage: true,
        canDeletePage: true,
        canEditPage: true,
        canExport: true,
        canViewPageVerification: true,
      },
      pageVerification: {
        canExport: true,
        canSave: true,
        canSaveAndApprove: true,
        canCheckLogs: true,
      },
    },
    fileInventoryModule: {
      fileCategory: {
        canUpload: true,
        canEdit: true,
        canDelete: true,
        canDownload: true,
      },
    },
    extractionSchemaModule: {
      listing: {
        canDeleteSchema: false,
        canCreateSchema: false,
        canViewSchema: true,
        canExport: true

      },
      createExtractionSchema: {
        canUploadBlankTemplate: false,
        canSave: false,
      },
    },
    efrwModule: {
      canExport: true,
    },
  },
  isReadOnlyUser: {
    dashboardModule: {},
    jobModule: {
      jobList: {
        canCreateJob: false,
        canDeleteOwnJob: false,
        canDeleteOthersJob: false,
        canEditJob: false,
      },
      jobDetail: {
        canStartJob: false,
        canReRunFiles: false,
        canExport: true,
      },
      fileDetail: {
        canStartDataExtraction: false,
        canCreateNewPage: false,
        canDeletePage: false,
        canEditPage: false,
        canExport: true,
        canViewPageVerification: true,
      },
      pageVerification: {
        canExport: true,
        canSave: false,
        canSaveAndApprove: false,
        canCheckLogs: true,
      },
    },
    fileInventoryModule: {
      fileCategory: {
        canUpload: false,
        canEdit: false,
        canDelete: false,
        canDownload: true,
      },
    },
    extractionSchemaModule: {
      listing: {
        canDeleteSchema: false,
        canCreateSchema: false,
        canViewSchema: true,
        canExport: true

      },
      createExtractionSchema: {
        canUploadBlankTemplate: false,
        canSave: false,
      },
    },
    efrwModule: {
      canExport: true,
    },
  },
};
