import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEndpoints } from '../microservices/process-controller/api-endpoints';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from './auth/auth.service';

export interface Message {
  source: string;
  content: string;
}

@Injectable()
export class WebsocketService {
  private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<Message>;

  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService) {}

  setConnectionTypeAndConnect(title?: string): void {
    let connectionType: string = title;
    if (title == '' || title == undefined) {
      connectionType =
        this.activatedRoute.snapshot.data.title ?? 'Notifications';
    }
    let WEBSOCKET_URL: string = ApiEndpoints.BASE_WEBSOCKET_URL;
    const params: any = {
      ...this.activatedRoute.snapshot.params,
      ...{
        title: connectionType,
      },
    };
    const queryString: string = Object.keys(params)
      .map((key: string): string => key + '=' + params[key])
      .join('&');

    WEBSOCKET_URL += `?${queryString}`;

    this.messages = this.connect(WEBSOCKET_URL).pipe(
      map((response: MessageEvent): Message => {
        console.log(response, 'Message event -- annonymousSubject');
        return response.data;
      })
    ) as Subject<Message>;
  }

  connect(url: string): AnonymousSubject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
      console.log('Successfully connected: ' + url);
    }
    return this.subject;
  }

  private create(url: string): AnonymousSubject<MessageEvent> {
    const ws: WebSocket = new WebSocket(url,[this.authService.getIdToken()]);
    const observable: Observable<MessageEvent<any>> = new Observable(
      (obs: Observer<MessageEvent>): void => {
        ws.onmessage = obs.next.bind(obs);
        ws.onerror = obs.error.bind(obs);
        ws.onclose = obs.complete.bind(obs);
        return ws.close.bind(ws);
      }
    );
    const observer: Observer<MessageEvent<any>> = {
      error: null,
      complete: (): void => {
        console.log(ws);
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
        }
        console.log('Connection closed');
      },
      next: (data: {}): void => {
        console.log('Message sent to websocket: ', data);
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      },
    };
    return new AnonymousSubject<MessageEvent>(observer, observable);
  }

  
}
