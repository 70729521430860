import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { IDialogAction, IDialogData } from '../../../models';
import { DeButtonComponent } from '../../atoms/de-button/de-button.component';
import { NgTemplateOutlet, NgFor, AsyncPipe } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'de-dialog',
    templateUrl: './de-dialog.component.html',
    styleUrls: ['./de-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        NgTemplateOutlet,
        MatDialogActions,
        NgFor,
        DeButtonComponent,
        AsyncPipe,
    ],
})
export class DeDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('textTemplate') textTemplate: TemplateRef<any>;

  constructor(
    private dialogRef: MatDialogRef<DeDialogComponent>,
    private cdref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) { }

  ngAfterViewInit() {
    if(this.data.template == 'text') {
      this.data.ngTemplate = this.textTemplate;
      this.cdref.detectChanges();
    }
  }
  ngOnInit(): void {}

  onButtonClick(action: IDialogAction) {
    if(action.fn) {
      action.fn(this.data.context);
      if(action.closeDialog) {
        this.dialogRef.close(this.data.context);
      }
    } else {
      this.dialogRef.close(null);
    }
  }
}
